import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2099.000000 2470.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,2470.000000) scale(0.100000,-0.100000)">



<path d="M860 24680 c-320 -68 -593 -280 -744 -578 -40 -79 -83 -217 -96 -307
-8 -58 -10 -3161 -8 -11490 l3 -11410 27 -93 c16 -51 44 -127 63 -168 150
-325 466 -565 810 -614 59 -8 2580 -10 9635 -8 l9555 3 77 22 c415 118 716
438 792 843 15 74 16 1168 16 11475 0 10307 -1 11401 -16 11475 -69 365 -331
675 -683 807 -36 14 -106 33 -156 44 -88 18 -291 19 -9640 18 -9192 0 -9553
-1 -9635 -19z m12605 -3400 c768 -20 1149 -54 1580 -141 789 -158 1468 -491
1915 -938 329 -329 571 -724 755 -1230 123 -339 204 -713 247 -1136 17 -169
17 -706 0 -890 -85 -922 -374 -1686 -878 -2320 -103 -129 -294 -328 -414 -429
-447 -380 -775 -552 -1497 -787 -73 -24 -131 -45 -129 -46 2 -2 93 -22 202
-44 331 -66 585 -138 793 -225 339 -142 712 -390 985 -654 419 -406 720 -896
904 -1471 82 -256 147 -594 177 -913 27 -285 20 -756 -15 -1066 -88 -779 -369
-1538 -787 -2127 -345 -486 -776 -844 -1383 -1148 -514 -257 -1077 -429 -1605
-490 -456 -52 -1154 -88 -2290 -120 -1069 -29 -1972 -35 -4973 -33 l-3012 3 5
40 c20 146 177 2063 229 2800 146 2036 282 5638 388 10285 7 312 13 1073 12
1783 l0 1237 60 -5 c334 -27 3010 -39 3871 -17 677 18 1483 40 1830 52 1088
35 2363 47 3030 30z m-8495 -18244 c238 -36 406 -119 547 -269 77 -84 129
-174 164 -287 21 -66 24 -95 24 -245 -1 -184 -8 -227 -64 -363 -92 -222 -280
-385 -528 -457 -150 -44 -268 -57 -453 -52 -142 4 -181 9 -280 35 -183 49
-284 101 -401 209 -217 202 -286 568 -168 892 103 282 391 486 764 540 96 14
292 12 395 -3z m5790 -11 c63 -10 117 -19 120 -22 3 -3 8 -48 11 -101 4 -53
10 -140 14 -193 l8 -96 -84 27 c-258 84 -485 48 -482 -76 2 -58 43 -105 225
-251 186 -151 229 -192 288 -284 47 -73 64 -141 57 -228 -11 -133 -83 -253
-196 -329 -178 -119 -406 -140 -823 -76 l-106 16 -6 67 c-3 36 -11 123 -17
192 -6 70 -10 131 -8 137 3 6 28 1 64 -14 100 -41 205 -65 311 -71 122 -7 191
7 234 47 37 34 40 86 10 135 -11 18 -98 101 -194 183 -209 183 -285 262 -322
339 -27 53 -29 67 -29 168 0 105 2 114 33 178 72 145 211 234 417 267 84 13
338 5 475 -15z m-7489 -23 c124 -35 192 -91 242 -199 19 -41 22 -65 22 -168 0
-100 -4 -129 -23 -175 -40 -101 -132 -185 -247 -225 -16 -5 -8 -11 37 -25 153
-48 242 -165 255 -335 15 -199 -87 -365 -267 -435 -111 -42 -223 -49 -779 -50
l-454 0 6 73 c31 370 46 687 54 1169 l6 388 361 3 c559 6 709 2 787 -21z
m9504 18 c164 -11 233 -27 335 -77 103 -50 212 -159 263 -264 66 -135 81 -210
81 -409 0 -149 -3 -187 -21 -257 -71 -267 -193 -432 -392 -532 -150 -76 -289
-91 -848 -91 l-423 0 0 23 c0 12 7 96 15 187 25 295 55 956 55 1247 l0 173
348 3 c191 1 370 3 397 4 28 2 113 -2 190 -7z m-6023 -275 c82 -148 211 -383
287 -522 l138 -252 6 97 c4 53 11 225 17 382 6 157 13 349 16 428 l6 142 234
0 c216 0 234 -1 234 -17 -1 -10 -5 -65 -10 -123 -9 -92 -23 -327 -60 -966 -5
-93 -12 -249 -16 -346 l-7 -178 -301 0 -301 0 -49 93 c-28 50 -105 186 -173
300 -127 214 -292 505 -335 591 l-25 49 -7 -159 c-21 -494 -26 -622 -26 -741
l0 -133 -240 0 c-132 0 -240 3 -240 8 0 4 5 47 10 97 18 153 60 946 66 1230 3
149 7 276 10 283 3 10 70 12 310 10 l307 -3 149 -270z m1738 233 c0 -24 -10
-171 -21 -328 -12 -157 -22 -384 -23 -505 -1 -191 1 -226 17 -267 22 -57 80
-110 139 -128 130 -38 244 -18 319 58 93 94 106 177 144 897 9 160 18 296 20
303 3 9 65 12 265 12 l260 0 0 -23 c0 -13 -11 -185 -25 -383 -14 -197 -30
-447 -35 -555 -19 -368 -77 -483 -304 -594 -149 -74 -312 -105 -546 -105 -379
0 -676 129 -750 326 -41 112 -43 195 -11 609 6 72 15 254 21 405 6 151 13 285
16 298 l4 22 255 0 255 0 0 -42z m6530 -243 c112 -323 419 -1149 487 -1312
l13 -33 -274 0 -275 0 -26 88 c-15 48 -48 150 -74 227 l-48 140 -302 3 -302 2
-68 -142 c-37 -79 -85 -182 -106 -231 l-39 -87 -284 0 -284 0 20 33 c58 97
118 207 293 537 106 201 233 439 281 530 49 91 130 247 180 348 l92 182 309
-2 309 -3 98 -280z m2084 163 c-3 -68 -7 -151 -10 -184 l-5 -61 -248 5 -248 5
-6 -44 c-17 -116 -47 -743 -47 -964 0 -71 -3 -160 -7 -197 l-6 -68 -264 0
-264 0 5 33 c19 119 54 679 63 991 l6 249 -253 -5 -253 -5 7 135 c3 74 6 157
6 184 l0 48 765 0 764 0 -5 -122z m1456 -163 c103 -295 403 -1108 463 -1258
l36 -87 -276 2 -275 3 -33 110 c-19 61 -52 163 -75 228 l-42 117 -300 0 -299
0 -68 -142 c-37 -79 -85 -182 -106 -231 l-39 -87 -283 0 c-156 0 -283 2 -283
5 0 2 22 42 49 87 26 46 95 171 151 278 57 107 171 321 253 475 82 154 208
394 279 533 l130 252 310 -2 310 -3 98 -280z"/>
<path d="M9176 18078 c-2 -7 -16 -305 -31 -663 -29 -735 -56 -1364 -85 -2015
-11 -245 -20 -451 -20 -458 0 -18 2345 -11 2540 7 357 33 739 124 955 226 342
163 618 504 734 906 172 594 58 1214 -285 1556 -271 271 -674 402 -1361 443
-265 16 -2443 14 -2447 -2z"/>
<path d="M8920 11705 c0 -17 -112 -2801 -128 -3180 -5 -110 -6 -204 -1 -208
11 -13 2576 -2 2744 12 402 32 589 65 834 147 192 64 360 141 480 221 322 215
563 661 627 1166 20 162 15 505 -10 642 -56 305 -168 534 -358 731 -233 241
-556 388 -963 438 -294 36 -376 38 -1802 43 -1325 4 -1423 3 -1423 -12z"/>
<path d="M4663 2679 c-223 -37 -384 -241 -384 -488 -1 -153 32 -243 121 -332
95 -95 209 -135 362 -127 165 10 301 101 371 249 148 312 2 649 -303 698 -74
12 -94 12 -167 0z"/>
<path d="M2606 2658 c-3 -23 -7 -94 -10 -156 l-5 -113 167 3 c190 3 225 14
264 76 28 45 30 127 5 165 -34 52 -67 61 -249 65 l-166 4 -6 -44z"/>
<path d="M2577 1979 c-4 -46 -7 -123 -7 -171 l0 -88 150 0 c172 0 238 11 282
48 85 72 76 218 -17 266 -35 18 -62 21 -221 24 l-181 4 -6 -83z"/>
<path d="M12303 2213 c-11 -252 -18 -460 -16 -462 7 -9 270 -2 321 8 221 44
340 233 330 526 -5 142 -26 207 -89 279 -76 87 -142 106 -365 106 l-160 0 -21
-457z"/>
<path d="M14545 2582 c-16 -37 -62 -136 -102 -220 -40 -84 -73 -155 -73 -158
0 -2 81 -4 180 -4 99 0 180 3 180 7 0 15 -145 443 -150 443 -3 0 -18 -31 -35
-68z"/>
<path d="M18077 2560 c-25 -52 -72 -153 -106 -224 -33 -70 -61 -130 -61 -132
0 -2 81 -4 180 -4 99 0 180 1 180 3 0 1 -34 103 -74 227 l-75 225 -44 -95z"/>
</g>
</svg>


    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}